div.range-Picker {
    position: absolute;
    height: auto;
    width: 400px;
    top: 200px;
    left: 200px;
    display: block;
    z-index: 101;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    background: #ffffff;
    color: #333333;
    outline: 0;
    padding: .2em;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

div.range-title {
    border: 1px solid #dddddd;
    background: #e9e9e9;
    color: #333333;
    font-weight: bold;
    touch-action: none;
    cursor: move;
    margin: 0;
    border-radius: 3px;
    padding: .4em 1em;
    position: relative;
    line-height: 26px;
}

div.range-content {
    text-align: center;
    min-width: 150px;
    width: auto;
    min-height: 24px;
    max-height: none;
    height: auto;
    position: relative;
    border: 0;
    padding: .5em 1em;
    background: none;
    overflow: auto;
    line-height: 26px;
}

div.range-content span {
    margin: .1em 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333333;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 26px;
}

div.range-content span.range-current {
    float: left;
    width: 100%;
}

div.range-buttons {
    margin: 0;
    border: solid #dddddd;
    text-align: center;
    border-width: 1px 0 0 0;
    background-image: none;
    margin-top: .5em;
    background: #ffffff;
    color: #333333;
}

div.range-buttons button {
    margin: .5em .4em .5em 1em;
    cursor: pointer;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 3px;
    color: #454545;
    text-decoration: none;
    border: 1px solid #c5c5c5;
    background: #f6f6f6;
    font-weight: normal;
    padding: .4em 1em;
    position: relative;
    line-height: normal;
    margin-right: .1em;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    -webkit-appearance: button;
    writing-mode: horizontal-tb !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
}

div.range-buttons button:hover {
    color: white;
    background-color: #61c2ec;
}

div.overlay {
    z-index: 100;
    background: #aaaaaa;
    opacity: .3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}