div.valuePicker {
  position: relative;
  border-radius: 3px;
  width: 100%;
  height: 90px;
  border: 1px #ccc solid;
  padding: 0 0.2em;
}

div.valuePicker ul {
  position: relative;
  text-align: center;
  width: 48%;
  height: 100%;
  float: left;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  cursor: pointer;
}

div.valuePicker ul:first-of-type {
  margin-right: 3%;
}

div.valuePicker ul li {
  border: 1px #ddd solid;
  background-color: #fff;
  margin: 1%;
}

div.valuePicker ul li.current {
  border: 1px #fff solid;
  color: white;
  background-color: #61c2ec;
}

/* ::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */
