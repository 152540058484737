.DWTController {
  float: right;
  width: 400px;
  height: 350px;
}

div.divinput {
  font-size: 14px;
  color: #606060;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: none;
}

.divType {
  padding-left: 20px;
  height: 38px;
  line-height: 36px;
  font-family: OpenSans-Semibold, Arial, sans-serif, Verdana, Helvetica;
  font-size: 14px;
  color: #444;
  border-bottom: solid 1px #ccc;
  background: #eee;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.mark_arrow {
  display: block;
  float: right;
  margin: 15px 20px 0 0;
  width: 12px;
  height: 8px;
}

.collapsed {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowMjkzMjc0RjYzN0IxMUU2OTNGMUEzOUQ4OEYzQUMxRiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowMjkzMjc1MDYzN0IxMUU2OTNGMUEzOUQ4OEYzQUMxRiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyOTMyNzRENjM3QjExRTY5M0YxQTM5RDg4RjNBQzFGIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyOTMyNzRFNjM3QjExRTY5M0YxQTM5RDg4RjNBQzFGIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+yMDUsAAAALdJREFUeNpirKmpmcbKypoJxAx//vxhYGRkZGBmZmb49u0bAy8vLwMIfP78mQEkz8bGNpmFi4srV0ZGRhoo7seAA0hKSoKoTUCNhYz///9n6O/v5wGCY0BBXRx6zv78+dMuJyfnG1gDCAA1yQCdchzIlEFT/ASILfPy8kA0A1wDCLS1telBNXFBhT5+//7dtqGh4TJMDYoGEGhubgb5ZR2UG1BbW7sFWR5DAwgATcyE0tPR5QACDACUyUGyqItEsAAAAABJRU5ErkJggg==)
    no-repeat center center;
}

.expanded {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDQTc2QThFRjYzN0ExMUU2QTZCNDhEMzY2OTM4MzExMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDQTc2QThGMDYzN0ExMUU2QTZCNDhEMzY2OTM4MzExMCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkNBNzZBOEVENjM3QTExRTZBNkI0OEQzNjY5MzgzMTEwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNBNzZBOEVFNjM3QTExRTZBNkI0OEQzNjY5MzgzMTEwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+uMs4sAAAAM9JREFUeNpi+P//PwM6bmtry6ypqcnEJsfEgAYqKyv9uLi4JnNzc08Gsr3Q5VE0FBcX6/Lw8CwDamAG0sy/fv1aBhLDqqGgoEDm9+/f23h5ebm/f//O8O/fPwZ5eXl+oDO2lZeXy8DUMYLclZaWxsXJyXlCXFxcl5mZGcUJIM3Pnj07DWQ6zJo16xvz69evmX/+/LlaRETEjomJieHv378oGCQGNET68+fPuhs3blzNrKurO1VMTCyGjY2NAVuogDArKysD0AXqP3784AMIMABBZn2zUHPjMgAAAABJRU5ErkJggg==)
    no-repeat center center;
}

.divTableStyle {
  padding: 12px 20px 15px;
  border-bottom: solid 1px #ccc;
}

.DWTController select,
.div_SaveImages input[type='text'] {
  margin-top: 3px;
  margin-bottom: 6px;
  height: 26px;
  border: solid 1px #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.DWTController input[type='checkbox'],
.DWTController input[type='radio'] {
  margin: 0 3px 0 0;
}

.majorButton {
  margin-top: 10px;
  margin-bottom: 5px;
  height: 30px;
  line-height: 28px;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 14px;
  font-family: OpenSans-Semibold, Arial, sans-serif, Verdana, Helvetica;
  cursor: default;
  color: rgb(255, 255, 255);
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

Button.disabled {
  background: #aaa;
}

Button.enabled {
  background: rgb(80, 168, 225);
}

Button.enabled:hover {
  background: #61c2ec;
}

.div_SaveImages li label {
  display: inline-block;
  margin: 0 8px 0 0;
  font-size: 12px;
}

.div_SaveImages li:nth-of-type(3) {
  margin: 5px 0 8px;
}

.div_SaveImages li:nth-of-type(2) {
  padding-top: 12px;
}
