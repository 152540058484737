.DWTcontainerTop {
    position: relative;
    float: left;
    width: 647px;
    height: 572px;
    border: 1px solid #ccc;
}

.DWTcontainerTop .divEdit {
    float: left;
    margin: 0;
    padding: 0;
    width: 60px;
    background: #fff;
    border-right: 1px solid #ccc;
}

.DWTcontainerTop .divEdit .operateGrp li {
    margin: 0;
    width: 60px;
    height: 57px;
    line-height: 52px;
    border-bottom: solid 1px #ccc;
    text-align: center;
}

.DWTcontainerTop .divEdit .operateGrp li:nth-last-child(2) {
    height: 58px;
}

.DWTcontainerTop .divEdit .operateGrp li:last-child {
    height: 56px;
    border-bottom: none;
}

.DWTcontainerTop .divEdit .operateGrp li>img {
    cursor: pointer;
}

.ds-dwt-container-box>div {
    border: none !important;
}

.ImgSizeEditor {
    padding: 10px 15px;
    position: absolute;
    z-index: 1;
    top: 513px;
    left: -1px;
    height: auto;
    width: 320px;
    font-size: 14px;
    color: #606060;
    text-align: left;
    background-color: #f5f5f5;
    border-collapse: collapse;
    border: 1px solid #ccc;
    box-shadow: 4px 4px 18px #ccc;
    -webkit-box-shadow: 4px 4px 18px #ccc;
    -moz-box-shadow: 4px 4px 18px #ccc;
}

.ImgSizeEditor li {
    margin-bottom: 5px;
    line-height: 19px;
    text-align: center;
}

.ImgSizeEditor input {
    background: #fff;
    border: solid 1px #ccc;
}

.ImgSizeEditor input[type='text'] {
    padding-left: 3px;
}

.ImgSizeEditor input[type='button'] {
    padding: 5px 5px;
    margin: 6px 5px 0;
}

.dynamsoft-dwt-container-box>div {
    border: none !important;
}

.barcodeInfoRect {
    position: absolute;
    z-index: 10;
    border: 1px solid red;
}

.image-container * {
    box-sizing: content-box;
}

.spanContainer {
    position: absolute;
    left: 0px;
    top: -14px;
    color: blue;
    font-size: 12px;
    font-weight: bold;
}

.navigatePanel {
    position: absolute;
    bottom: 0;
    padding: 14px 25px;
    height: 57px;
    background: #f5f5f5;
    border-top: 1px solid #ccc;
    font-size: 14px;
    color: #606060;
}

.navigatePanel input[type='text'] {
    height: 20px;
    width: 30px;
    font-size: 12px;
    border: 1px solid #ccc;
    cursor: default;
}

.previewMode {
    float: right;
    padding: 0 5px;
    margin-left: 5px;
    height: 26px;
    border: solid 1px #d8d8d8;
    color: #444;
    border-radius: 3px;
    cursor: pointer;
}